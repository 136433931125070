@import '../global.style.scss';

div.Banner {
  background-color: $darkgrey;
  text-align: center;
  font-weight: normal;
  background-image: url('https://images.prismic.io/yogifm/d1370db2-d005-4491-b239-67ee2d79c5f8_banner.jpg?auto=compress,format');
  background-size: cover;
  background-position: center center;
  font-size: 1.5em;
  padding: 125px 25px;
  box-sizing: border-box;
  text-shadow: 2px 2px 25px #363636;

  > h2 {
    margin: -5px;
    font-size: 4em;
  }

  > p {
    max-width: 400px;
    margin: 5px auto;
  }
}
