@import '../global.style.scss';

div.Featured {
  span.title {
    font-size: 1.25em;
    font-weight: bold;
    display: block;
    margin: 15px;
  }

  > div {
    div.collection {
      padding: 0px 15px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow-x: scroll;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      > a {
        display: inline-block;
        margin-right: 15px;
        color: $white;
        text-decoration: none;
        > div {
          height: 150px;
          width: 150px;
          background-color: $grey;
          margin-bottom: 10px;
          cursor: pointer;
          background-size: cover;
        }

        > span {
          text-transform: capitalize;
          font-size: 0.85em;
          display: block;
        }

        span.years {
          color: #969696;
        }

        > div:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

@media only screen and (max-width: $large) {
  div.Featured {
    > div {
      div.collection {
        > a {
          > div {
            height: 125px;
            width: 125px;
          }
          > span.years {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $medium) {
  div.Featured {
    > div {
      div.collection {
        grid-template-columns: 1fr 1fr;
        > a {
          > div {
            height: 175px;
            width: 175px;
          }
        }
      }
    }
  }
}
