/* colors */
$white: #ffffff;
$extralightgrey: #7e7e7e;
$lightgrey: #42413D;
$grey: #282828;
$darkgrey: #0B0A0A;
$blue: #348dd6;

/* fonts */
$opensans: 'Open Sans', sans-serif;
$roboto: 'Roboto', sans-serif;

/* screen sizes */
$large: 1575px;
$medium: 1350px;
$small: 1200px;
$mobile: 900px;