@import './global.style.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $lightgrey;
  color: $white;
  font-family: $roboto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

h1,
h2 {
  margin: 0px;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:active {
  outline: none;
  border: none;
}